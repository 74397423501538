
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  setup() {
    const route = useRoute();
    const result = ref();
    const getResult = async () => {
      result.value = await ApiService.get(
        `/game/result/${route.params.betId}`
      ).then((res) => res.data);
    };

    const totalBet = computed(() => {
      let totalBet = 0;
      if (result.value && result.value.positions) {
        let bets = result.value.positions;
        bets.forEach((bet) => {
          totalBet = Number(totalBet) + Number(bet.betamount);
        });
      }
      return totalBet;
    });

    const totalWin = computed(() => {
      let totalWin = 0;
      if (result.value) {
        let bets = result.value.positions;
        bets.forEach((bet) => {
          totalWin = Number(totalWin) + Number(bet.winamount);
        });
      }
      return totalWin;
    });
    const totalNet = computed(() => {
      let totalNet = 0;
      if (result.value) {
        let bets = result.value.positions;
        bets.forEach((bet) => {
          totalNet =
            Number(totalNet) + (Number(bet.winamount) - Number(bet.betamount));
        });
      }
      return totalNet;
    });

    onBeforeMount(() => getResult());

    return {
      totalBet,
      totalWin,
      totalNet,
      result,
    };
  },
});
